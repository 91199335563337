export const getLeftMenuRouteData = (data: any): TtcRoute[] => {
  let ttcRoutedata: TtcRoute[] = [];
  if (data) {
    for (let section = 0; section < data.length; section++) {
      const menuItems: any[] = data[section].items;
      var routes = getPagePath(menuItems);
      if (routes.length > 0) {
        ttcRoutedata = [...ttcRoutedata, ...routes];
      }
    }
  }
  return ttcRoutedata;
};

const getPagePath = (menuItems: any[]): TtcRoute[] => {
  let routes: TtcRoute[] = [];
  for (let menuIndex = 0; menuIndex < menuItems.length; menuIndex++) {
    let menu: any = menuItems[menuIndex];
    if (!menu.isAtBottomOfLeftMenu) {
      const route: TtcRoute[] =
        menu.items !== null && menu.items !== undefined && menu.items.length > 0
          ? getPagePath(menu.items)
          : [{ route: menu.route, isEnabled: !menu.disabled }];
      route.forEach((ele) => {
        if (ele.route !== "") {
          routes.push(ele);
        }
      });
    }
  }
  return routes;
};
