export const AccessDeniedIcon: React.FC<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} fill="none" viewBox="0 0 200 200">
    <circle cx="100" cy="100" r="100" fill="#E6EBF0"></circle>
    <path
      fill="#B4C3D3"
      d="M132.974 47.887H67.026c-10.571.004-19.135 8.492-19.139 18.97v65.362c0 1.45 1.187 2.626 2.65 2.626 1.463 0 2.65-1.176 2.65-2.626V79.287h93.626v52.932c.004 7.574-6.19 13.713-13.832 13.716H67.026c-2.403 0-4.763-.623-6.85-1.8a2.662 2.662 0 00-3.544 1.208 2.614 2.614 0 00.922 3.345 19.288 19.288 0 009.472 2.486h65.948c10.571 0 19.139-8.492 19.139-18.969V66.856c-.004-10.477-8.572-18.965-19.139-18.969zm13.839 18.97v7.178H53.187v-7.179c-.004-7.574 6.19-13.713 13.832-13.716h65.955c7.642-.004 13.836 6.135 13.839 13.71v.006z"
    ></path>
    <path
      fill="#B4C3D3"
      d="M80.285 60.094a3.286 3.286 0 10-.007 6.573 3.286 3.286 0 003.29-3.283v-.007a3.284 3.284 0 00-3.283-3.283zM67.61 60.094c-2.075-.004-3.756 1.467-3.76 3.283-.004 1.815 1.677 3.286 3.752 3.29 2.075.003 3.755-1.468 3.76-3.283v-.007c-.004-1.812-1.681-3.28-3.752-3.283zM94.37 60.094a3.286 3.286 0 10-.007 6.573 3.286 3.286 0 00.007-6.573zM86.671 122.693c.372.396.813.711 1.3.926a3.805 3.805 0 001.534.325c.526 0 1.047-.111 1.534-.325.486-.215.927-.53 1.299-.926l7.709-8.193 7.714 8.193a3.884 3.884 0 002.804 1.143 3.9 3.9 0 002.764-1.248 4.397 4.397 0 001.174-2.938 4.42 4.42 0 00-1.076-2.98l-7.708-8.192 7.708-8.193a4.409 4.409 0 001.127-2.996 4.4 4.4 0 00-1.173-2.977 3.894 3.894 0 00-2.8-1.25 3.884 3.884 0 00-2.82 1.195l-7.714 8.193-7.71-8.193a4.014 4.014 0 00-1.3-.954 3.804 3.804 0 00-3.1-.027c-.494.214-.942.531-1.318.931-.376.4-.674.876-.875 1.4a4.495 4.495 0 00.028 3.296c.21.52.516.99.9 1.382l7.687 8.193-7.709 8.192a4.295 4.295 0 00-.865 1.385 4.493 4.493 0 00.012 3.26c.203.516.5.984.874 1.378z"
    ></path>
  </svg>
);

export const DisabledByTierIcon: React.FC<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} fill="none" viewBox="0 0 200 200">
    <circle cx="100" cy="100" r="100" fill="#E6EBF0"></circle>
    <path
      fill="#B4C3D3"
      fillRule="evenodd"
      stroke="#B4C3D3"
      strokeWidth="3"
      d="M134.19 64.856a48.503 48.503 0 0110.622 16.001 48.39 48.39 0 01-4.706 45.2 48.403 48.403 0 01-17.391 15.682 48.372 48.372 0 01-50.907-3.37 48.317 48.317 0 01-17.62-23.796 48.746 48.746 0 01-2.223-21.138 2.46 2.46 0 114.885.57A43.851 43.851 0 0058.847 113a43.528 43.528 0 009.248 15.543c.396-.535.854-1.066 1.358-1.57l58.739-58.74c.41-.409.838-.788 1.271-1.128a43.428 43.428 0 00-13.121-8.315 43.79 43.79 0 00-32.69 0 43.141 43.141 0 00-14.373 9.545 2.461 2.461 0 01-4.012-.798 2.459 2.459 0 01.533-2.68 48.055 48.055 0 0116-10.624 48.682 48.682 0 0136.391 0 48.502 48.502 0 0115.999 10.623zm-1.282 5.81a43.427 43.427 0 01-2.193 59.109 43.198 43.198 0 01-14.373 9.541 43.74 43.74 0 01-32.69 0 43.513 43.513 0 01-12.067-7.396 14.104 14.104 0 001.505-1.309l58.74-58.74c.389-.389.751-.795 1.078-1.206z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export const ProductDisabledIcon: React.FC<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} fill="none" viewBox="0 0 200 200">
    <circle cx="100" cy="100" r="100" fill="#E6EBF0"></circle>
    <path
      fill="#B4C3D3"
      d="M76.552 72.227c.351-.72.403-1.55.144-2.31a3.019 3.019 0 00-1.523-1.735 2.997 2.997 0 00-2.3-.144c-.755.26-1.376.81-1.727 1.53L42.382 128.57c-1.203 2.461-4.411 9.004 1.204 13.5 8.382 6.696 32.164 10.048 55.954 10.044 23.791-.004 47.57-3.348 55.936-10.044 5.614-4.496 2.406-11.043 1.203-13.5l-37.411-76.775a22.032 22.032 0 00-8.103-9.015 21.897 21.897 0 00-11.625-3.342c-4.11 0-8.137 1.158-11.624 3.342a22.035 22.035 0 00-8.103 9.014 3.035 3.035 0 00-.146 2.31 3.018 3.018 0 001.521 1.735 2.997 2.997 0 002.299.147 3.014 3.014 0 001.728-1.529 15.997 15.997 0 015.883-6.549 15.897 15.897 0 0116.885 0 15.991 15.991 0 015.883 6.55l37.435 76.771c2.093 4.294 1.431 5.31.425 6.115-14.631 11.716-89.744 11.716-104.375 0-1.002-.805-1.664-1.817.434-6.115l28.767-59z"
    ></path>
    <path
      fill="#B4C3D3"
      d="M104.225 104.464V76.757c0-.933-.445-1.828-1.237-2.487-.793-.66-1.867-1.03-2.988-1.03-1.12 0-2.195.37-2.988 1.03-.792.66-1.237 1.554-1.237 2.487v27.707c0 .933.445 1.827 1.237 2.487.793.66 1.867 1.03 2.988 1.03s2.195-.37 2.988-1.03c.792-.66 1.237-1.554 1.237-2.487zM93.897 122.066c0 1.114.358 2.203 1.028 3.129a6.016 6.016 0 002.738 2.075 6.573 6.573 0 003.526.322 6.28 6.28 0 003.125-1.541 5.511 5.511 0 001.671-2.883 5.234 5.234 0 00-.345-3.255 5.75 5.75 0 00-2.246-2.529 6.466 6.466 0 00-3.389-.952c-1.62 0-3.172.593-4.317 1.65-1.146 1.056-1.79 2.489-1.791 3.984z"
    ></path>
  </svg>
);

export const NoPermissionIcon: React.FC<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} fill="none" viewBox="0 0 200 200">
    <circle cx="100" cy="100" r="100" fill="#E6EBF0"></circle>
    <path
      fill="#B4C3D3"
      fillRule="evenodd"
      stroke="#B4C3D3"
      strokeWidth="3"
      d="M121.382 80.957a29.676 29.676 0 016.497 9.786A29.59 29.59 0 01125 118.39a29.592 29.592 0 01-41.774 7.531 29.555 29.555 0 01-10.778-14.555 29.818 29.818 0 01-1.36-12.93 1.504 1.504 0 112.989.35 26.815 26.815 0 001.221 11.617 26.628 26.628 0 005.657 9.508c.242-.327.522-.652.83-.96l35.928-35.928c.251-.251.512-.483.777-.691a26.55 26.55 0 00-8.025-5.086 26.784 26.784 0 00-19.995 0 26.386 26.386 0 00-8.791 5.838 1.505 1.505 0 01-2.128-2.127 29.392 29.392 0 019.786-6.498 29.776 29.776 0 0122.259 0 29.678 29.678 0 019.786 6.498zm-.784 3.553a26.569 26.569 0 01-1.342 36.154 26.437 26.437 0 01-8.791 5.836 26.756 26.756 0 01-19.995 0 26.626 26.626 0 01-7.38-4.524c.313-.235.624-.505.92-.8l35.928-35.929c.238-.238.46-.486.66-.737z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#B4C3D3"
      d="M154.848 59.189a86.865 86.865 0 01-49.325-14.326 9.807 9.807 0 00-10.951 0A86.466 86.466 0 0150.249 59.19a9.758 9.758 0 00-6.084 2.41 9.742 9.742 0 00-3.188 5.71c-3.046 17.357-7.99 75.513 56.179 94.721a10.07 10.07 0 002.891.411c.978.001 1.951-.139 2.888-.415 75.79-22.696 55.109-99.968 54.893-100.745a2.877 2.877 0 00-2.98-2.092zm-1.417 42.857c-5.129 27.313-22.68 45.647-52.159 54.475-.8.238-1.653.238-2.453 0-19.318-5.805-63.075-25.978-52.155-88.22a4.023 4.023 0 013.82-3.35 92.303 92.303 0 0047.338-15.318 3.976 3.976 0 014.451 0 90.733 90.733 0 0050.507 15.388 97.877 97.877 0 01.651 37.025z"
    ></path>
  </svg>
);
