import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SessionTimeoutHelper } from "@sssuite-component-ui/session-timeout";

import { AppState } from "store";
import { signoutRedirect } from "oidc-client/userService";
import { setLoggedOut } from "store/slices/post-auth-slice";
import { useAppSelector } from "./redux-hooks";

export const useLogout = () => {
  const dispatch = useDispatch();
  const { device_id } = useAppSelector((state: AppState) => state.authReducer?.authentication?.user?.profile);

  const handleLogout = () => {
    SessionTimeoutHelper.logoutHelper(device_id, signoutRedirect);
    dispatch(setLoggedOut(true));
  };

  useEffect(() => {
    // This effect runs when component mounts
    // You might want to do something here if needed
    return () => {
      // This cleanup runs when component unmounts
      // You might want to do cleanup here if needed
    };
  }, []); // Empty dependency array to ensure effect runs only once on mount

  return handleLogout;
};
