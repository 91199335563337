const _ = require("lodash");

export const findMaxIdInTree = (nodes: any, maxId: number) => {
  nodes.forEach((node: any) => {
    if (node.id > maxId) {
      maxId = node.id;
    }
    if (node.items) {
      maxId = findMaxIdInTree(node.items, maxId);
    }
  });
  return maxId;
};

export const updateNode = (tree: any[], id: number, newText: any, canEdit: boolean): any[] => {
  return tree.map((node: { id: number; items: any }) => {
    if (node.id === id) {
      return { ...node, name: newText, expanded: true, canEdit };
    } else if (node.items) {
      return { ...node, items: updateNode(node.items, id, newText, canEdit) };
    }
    return node;
  });
};

export const addChildNode = (tree: any[], parentId: number, canEdit: boolean): any[] => {
  let maxId = 0;
  maxId = findMaxIdInTree(tree, parentId);
  const newId = maxId + 1;
  return tree.map((node: { id: number; items: any }) => {
    if (node.id === parentId) {
      return {
        ...node,
        items: [...(node.items || []), { id: newId, name: "New Bookmark", canEdit, items: [] }]
      };
    } else if (node.items) {
      return { ...node, items: addChildNodeWithId(node.items, parentId, canEdit, newId) };
    }
    return node;
  });
};

export const addChildNodeWithId = (tree: any[], parentId: number, canEdit: boolean, newId: number): any[] => {
  return tree.map((node: { id: number; items: any }) => {
    if (node.id === parentId) {
      return {
        ...node,
        items: [...(node.items || []), { id: newId, name: "New Bookmark", canEdit, items: [] }]
      };
    } else if (node.items) {
      return { ...node, items: addChildNodeWithId(node.items, parentId, canEdit, newId) };
    }
    return node;
  });
};

export const deleteChildNode = (tree: any[], id: number) => {
  if (id === 0) return tree;
  return tree.filter((node: { id: number; items: any }) => {
    if (node.id === id) {
      return false;
    } else if (node.items) {
      node.items = deleteChildNode(node.items, id);
      return true;
    }
    return true;
  });
};

export const moveNodeUp = (tree: any[], id: number): any[] => {
  return tree.map((node: { items: any[] }) => {
    if (node.items) {
      const index = node.items.findIndex((child: { id: any }) => child.id === id);
      if (index > 0) {
        const temp = node.items[index];
        node.items[index] = node.items[index - 1];
        node.items[index - 1] = temp;
      }
      return { ...node, items: moveNodeUp(node.items, id) };
    }
    return node;
  });
};

export const moveNodeDown = (tree: any[], id: number): any[] => {
  return tree.map((node: { items: any[] }) => {
    if (node.items) {
      const index = node.items.findIndex((child: { id: any }) => child.id === id);
      if (index < node.items.length - 1 && index !== -1) {
        const temp = node.items[index];
        node.items[index] = node.items[index + 1];
        node.items[index + 1] = temp;
      }
      return { ...node, items: moveNodeDown(node.items, id) };
    }
    return node;
  });
};

export const indentNodeRight = (tree: any[], id: number): any[] => {
  return tree.map((node: { items: any[] }) => {
    if (node.items) {
      const index = node.items.findIndex((child: { id: number }) => child.id === id);
      if (index !== -1 && index > 0) {
        const removedNode = node.items.splice(index, 1)[0];
        node.items[index - 1].items = node.items[index - 1].items || [];
        node.items[index - 1].items.push(removedNode);
      }
      return { ...node, items: indentNodeRight(node.items, id) };
    }
    return node;
  });
};

export const indentNodeLeft = (tree: any[], id: number): any[] => {
  const findParent = (nodes: any[], parentId: number): any => {
    for (const node of nodes) {
      if (node.items) {
        if (node.items.some((child: { id: any }) => child.id === parentId)) {
          return node;
        } else {
          const found = findParent(node.items, parentId);
          if (found) return found;
        }
      }
    }
    return null;
  };

  const findNode = (nodes: any[], nodeId: number): any => {
    for (const node of nodes) {
      if (node.id === nodeId) {
        return node;
      } else if (node.items) {
        const found = findNode(node.items, nodeId);
        if (found) return found;
      }
    }
    return null;
  };

  const updatedTree = _.cloneDeep(tree);

  const nodeToIndent = findNode(updatedTree, id);
  if (!nodeToIndent) return tree;

  const parent = findParent(updatedTree, id);
  if (!parent) return tree;

  const indexInParent = parent.items.findIndex((child: { id: number }) => child.id === id);
  if (indexInParent === -1) return tree;

  if (indexInParent === 0 && parent !== updatedTree) {
    // If the node is the first child of its parent, make it a sibling of its parent
    const grandParent = findParent(updatedTree, parent.id);
    if (grandParent) {
      grandParent.items.push(nodeToIndent);
      parent.items.splice(indexInParent, 1);
    }
  } else {
    // Otherwise, make it a child of its previous sibling's last child
    const prevSibling = parent.items[indexInParent - 1];
    const lastChild = prevSibling.items ? prevSibling.items[prevSibling.items.length - 1] : null;
    if (lastChild) {
      lastChild.items = lastChild.items || [];
      lastChild.items.push(nodeToIndent);
    } else {
      prevSibling.items = prevSibling.items || [];
      prevSibling.items.push(nodeToIndent);
    }
    parent.items.splice(indexInParent, 1);
  }

  return updatedTree;
};

export const expandAllNodes = (treeData: any) => {
  const expandAll = (nodes: any[]): any => {
    return nodes.map((node: { items: any }) => {
      return { ...node, expanded: true, items: node.items ? expandAll(node.items) : null };
    });
  };
  return expandAll(treeData);
};

export const collapseAllNodes = (treeData: any) => {
  const collapseAll = (nodes: any[]): any => {
    return nodes.map((node: { items: any }) => {
      return { ...node, expanded: false, items: node.items ? collapseAll(node.items) : null };
    });
  };
  return collapseAll(treeData);
};

export const toggleNodeExpand = (tree: any[], id: number): any[] => {
  return tree.map((node: { id: number; expanded: any; items: any }) => {
    if (node.id === id) {
      return { ...node, expanded: !node.expanded };
    } else if (node.items) {
      return { ...node, items: toggleNodeExpand(node.items, id) };
    }
    return node;
  });
};
