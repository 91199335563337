import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PasswordOptions, PasswordPolicyType } from "common/enums";
import { PasswordState } from "common/types/password";

const initialState: PasswordState = {
  passwordState: {
    password: "",
    newPassword: ""
  },
  passwordPolicies: {
    passwordPolicy: PasswordPolicyType.NoPolicy,
    passwordSettings: {
      passwordOptions: PasswordOptions.None,
      length: 0,
      passwordAge: 0
    },
    modifiedDate: new Date()
  },
  isLoading: false
};

const passwordSlice = createSlice({
  name: "password",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setPassword: (state, action) => {
      state.isLoading = false;
    },
    setPasswordPolicy: (state, action) => {
      state.passwordPolicies = action.payload;
      state.isLoading = false;
    }
  }
});
export const { setIsLoading, setPassword, setPasswordPolicy } = passwordSlice.actions;
export default passwordSlice.reducer;
