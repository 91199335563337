import { FC, ReactNode } from "react";
import Modal from "./modal/Modal";
import Button, { ButtonType } from "./button/Button";

interface ConfirmModalProps {
  show: boolean;
  title: string;
  className?: string;
  cancelButtonName?: string;
  confirmButtonName?: string;
  additionalActionButtonName?: string;
  children: JSX.Element;
  additionalFooterElement?: ReactNode;
  onSubmit: () => void;
  onHide: () => void;
  onAdditionalAction?: () => void;
}

const ConfirmModal: FC<ConfirmModalProps> = (props) => {
  const getFooter = () => {
    return (
      <>
        {props.additionalFooterElement && <div className="additional-action">{props.additionalFooterElement}</div>}
        <Button buttonType={ButtonType.Light} onClick={props.onHide}>
          {props.cancelButtonName || "Cancel"}
        </Button>
        {props.onAdditionalAction && (
          <Button buttonType={ButtonType.Light} onClick={props.onAdditionalAction}>
            {props.additionalActionButtonName}
          </Button>
        )}
        <Button buttonType={ButtonType.Danger} onClick={props.onSubmit}>
          {props.confirmButtonName || "Ok"}
        </Button>
      </>
    );
  };

  return (
    <Modal className={props.className} isOpen={props.show} onClose={props.onHide} title={props.title} footer={getFooter()}>
      {props.children}
    </Modal>
  );
};

export default ConfirmModal;
