import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialPostAuthState = {
  loggedOut: false,
  isLoading: false,
  productEnabled: {
    isLoading: false,
    isEnabled: false
  },
  productSubscribed: {
    isLoading: false,
    isSubscribed: false
  },
  ttcEnabled: false,
  isPageAccessDenied: false
};

const postAuthSlice = createSlice({
  name: "postAuth",
  initialState: initialPostAuthState,
  reducers: {
    setLoggedOut(state, action: PayloadAction<any>) {
      state.loggedOut = action.payload;
    },
    setIsLoading(state, action: PayloadAction<any>) {
      state.isLoading = action.payload;
      state.productEnabled.isLoading = action.payload;
      state.productSubscribed.isLoading = action.payload;
    },
    setProductEnabled(state, action: PayloadAction<any>) {
      state.productEnabled.isEnabled = action.payload?.isEnabled;
      state.productEnabled.isLoading = false;
    },
    setProductSubscribed(state, action: PayloadAction<any>) {
      state.productSubscribed.isSubscribed = action.payload?.isSubscribed;
      state.productSubscribed.isLoading = false;
    },
    setTTCEnabled(state, action: PayloadAction<any>) {
      state.ttcEnabled = action.payload;
      state.isLoading = false;
    },
    setPageLevelAccess(state, action: PayloadAction<any>) {
      state.isPageAccessDenied = action.payload;
    }
  }
});

export const { setLoggedOut, setIsLoading, setProductEnabled, setProductSubscribed, setTTCEnabled, setPageLevelAccess } =
  postAuthSlice.actions;
export default postAuthSlice.reducer;
