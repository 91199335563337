import React from "react";
import ErrorPage from "common/components/pages/ErrorPage";

type ErrorProps = {
  fallback?: any;
  children?: any;
};
type ErrorState = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    console.log("Error: ", error, info.componentStack);
  }

  resetError = () => {
    this.setState({ hasError: false });
  };
  render() {
    if (this.state.hasError) {
      return <>{this.props.fallback || <ErrorPage isResetEnabled resetError={this.resetError} />}</>;
    }
    return this.props.children;
  }
}
