import { createSlice } from "@reduxjs/toolkit";

const initialState: OneTimePasswordState = {
  otpState: {
    mfaOTPLength: 0,
    isOTPValid: false
  },
  isLoading: false
};

const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {
    setMfaOTPLength(state, action) {
      state.otpState.mfaOTPLength = action.payload;
      state.isLoading = action.payload;
    },
    setIsOTPValid(state, action) {
      state.otpState.isOTPValid = action.payload;
      state.isLoading = false;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});
export const { setMfaOTPLength, setIsOTPValid, setIsLoading } = otpSlice.actions;
export default otpSlice.reducer;
