import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { signinRedirect, signinRedirectWithCallbackArgs } from "oidc-client/userService";
import LoadingView from "common/components/pages/LoadingScreen";

const Login: FC = (props: any) => {
  const { user, isLoadingUser } = useSelector((state: any) => state);
  if (!user) {
    if (isLoadingUser) {
      return <LoadingView showLogo={true} />;
    }
    if (
      props.location &&
      props.location.state &&
      props.location.state.redirectedFrom &&
      props.location.state.redirectedFrom.pathname !== "/"
    ) {
      signinRedirectWithCallbackArgs(
        props.location.state.redirectedFrom.pathname +
          (props.location.state.redirectedFrom.search ? props.location.state.redirectedFrom.search : "")
      );
    } else {
      signinRedirect();
    }
    return <></>;
  }
  return <Navigate to={"/"} />;
};

Login.displayName = "Login";
export default Login;
