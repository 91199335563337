import React, { FC, useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import * as Validation from "../../../helper/validations";
import { phoneNumberDisplay } from "helper/HelperFunctions";

interface PhoneNumberProps {
  phoneNumber: string;
  handleChangePhoneNumber(value: string): void;
  disabled: boolean;
  onBlur?: (value: any) => void;
  datatestAuto?: string;
  className?: string;
  required?: boolean;
  isInvalid?: boolean;
}
interface PhoneNumberState {
  phoneNumberState: string;
}

export const PhoneNumber: FC<PhoneNumberProps> = (props) => {
  const [state, setState] = useState<PhoneNumberState>({
    phoneNumberState: ""
  });

  useEffect(() => {
    phoneNumberDisplayValue();
  }, [props.phoneNumber]);

  const onBlur = (value: any) => {
    if (props.onBlur) {
      props.onBlur(value);
    }
  };
  const onChangePhoneNumber = (event: any) => {
    const value = event.target.value;
    const length = value.length;
    let phoneNumber = "";
    if (value !== "") {
      if (Validation.ValidateTenDigitphoneNumber(value)) {
        if (length <= 5) {
          if (length > 1) {
            phoneNumber = value.replace("(", "");
            phoneNumber = phoneNumber.replace(")", "");
            if (
              (state.phoneNumberState.includes(")") && !value.includes(")")) ||
              (state.phoneNumberState.includes("(") && !value.includes("("))
            )
              phoneNumber = value;
            else if (length > 3) phoneNumber = "(" + phoneNumber + ")";
            else phoneNumber = value;
          } else {
            if (length < state.phoneNumberState.length && length > 1) phoneNumber = value;
            else {
              phoneNumber = !value.includes("(") ? "(" : "";
              phoneNumber = phoneNumber != "" ? phoneNumber + value + ")" : "";
            }
          }
          setState({ phoneNumberState: phoneNumber });
        }
        if (length >= 6 && length <= 12) {
          if (length == 6 && !value.includes(" ")) phoneNumber = state.phoneNumberState + " " + value.substring(6, 5);
          else phoneNumber = value;
          setState({ phoneNumberState: phoneNumber });
        }
        if (length >= 10) {
          if (length == 10 && !value.includes("-"))
            phoneNumber = state.phoneNumberState + "-" + event.target.value.substring(10, 9);
          else phoneNumber = value;
          setState({ phoneNumberState: phoneNumber });
        }
        phoneNumber = replaceBrackets(value);
        props.handleChangePhoneNumber(phoneNumber);
      }
    } else {
      phoneNumber = value;
      setState({ phoneNumberState: phoneNumber });
      props.handleChangePhoneNumber(phoneNumber);
    }
  };

  const replaceBrackets = (value: string) => {
    let res = value.replace("(", "");
    res = res.replace(")", "");
    res = res.replace(" ", "");
    res = res.replace("-", "");
    return res;
  };

  const phoneNumberDisplayValue = () => {
    const phoneNumber = props.phoneNumber;
    const phoneNumberDisplayvalue: any = phoneNumberDisplay(phoneNumber);
    setState({ phoneNumberState: phoneNumberDisplayvalue });
  };

  return (
    <Form.Control
      type="text"
      size="sm"
      data-lpignore="true"
      data-test-auto={props.datatestAuto ? props.datatestAuto : "F22C7D00-409F-48E3-AD55-5341EF451C9B"}
      className={props.className ? props.className : ""}
      placeholder="( ) -"
      value={state.phoneNumberState}
      onChange={onChangePhoneNumber}
      onBlur={onBlur}
      disabled={props.disabled}
      required={props.required}
      isInvalid={props.isInvalid}
    ></Form.Control>
  );
};
