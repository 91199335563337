import { Action, ThunkAction } from "@reduxjs/toolkit";
import { UserProfile, UserProfileData } from "common/types/userProfile";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { setIsLoading, setUserProfile, setUserRoles } from "store/slices/user-profile-slice";

export const getUserRoles =
  (userId: number): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      Axios.get(`${API.SUITE_USER_GROUP.GET_USER_ROLES}/${userId}`, {
        method: "GET",
        credentials: "include"
      }).then((data) => {
        data.roles && data.roles.length > 0 && dispatch(setUserRoles(data.roles.map((role: any) => role.roleName)));
      });
    } catch (error: any) {
      // handle error
      dispatch(setIsLoading(false));
    }
  };

export const getUserProfile = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    Axios.get(`${API.SUITE_USER_MANAGEMENT.USER_DETAILS}`, {
      method: "GET",
      credentials: "include"
    }).then((data) => {
      dispatch(setUserProfile(data));
    });
  } catch (error: any) {
    // handle error
    dispatch(setIsLoading(false));
  }
};

export const saveUserProfile =
  (
    userInfo: UserProfile,
    notification: boolean,
    onResponse?: (response: any, error: any) => void
  ): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      Axios.put(`${API.SUITE_USER_MANAGEMENT.USER_DETAILS}`, userInfo, {
        method: "POST",
        credentials: "include"
      }).then((response) => {
        const result: UserProfileData = {
          userProfile: response.data,
          isLoading: false
        };
        dispatch(setUserProfile(userInfo));
        onResponse && onResponse(result, null);
      });
    } catch (error: any) {
      // handle error
      dispatch(setIsLoading(false));
    }
  };
