import React, { FC } from "react";
import { PageDisabledTypes } from "common/enums";
import { AccessDeniedConstants, FeatureDisabledConstants } from "helper/constants";
import Button, { ButtonType } from "../button/Button";
import { useLogout } from "common/hooks/useLogout";
import { AccessDeniedIcon, DisabledByTierIcon, NoPermissionIcon, ProductDisabledIcon } from "assets/svg/FeatureDisableIcons";
import "./../styles/FeatureDisabled.scss";

interface IFeatureDisabledProps {
  pageDisabledType: number;
}

const FeatureDisabled: FC<IFeatureDisabledProps> = ({ pageDisabledType }) => {
  const handleLogout = useLogout();

  const getTitle = () => {
    switch (pageDisabledType) {
      case PageDisabledTypes.NoPermission:
        return FeatureDisabledConstants.DeniedTitle;
      case PageDisabledTypes.PageAccessDenied:
        return AccessDeniedConstants.AccessDeniedTitle;
      default:
        return FeatureDisabledConstants.DisabledTitle;
    }
  };

  const getDescription = () => {
    switch (pageDisabledType) {
      case PageDisabledTypes.NoPermission:
        return (
          <div>
            {FeatureDisabledConstants.NoPermissionDescription}
            <span style={{ fontWeight: 500 }}>{FeatureDisabledConstants.ProductName}</span>
            {FeatureDisabledConstants.NoPermissionContactAdministrator}
          </div>
        );
      case PageDisabledTypes.ProductDisabled:
        return FeatureDisabledConstants.ProductDisabledInSettingsDescription;
      case PageDisabledTypes.SubscriptionTier:
        return (
          <div>
            {FeatureDisabledConstants.DisabledByTierDescription}
            <span style={{ fontWeight: 500 }}>{FeatureDisabledConstants.DisabledByTierCustomerSuccessRepresentative}</span>
            {FeatureDisabledConstants.DisabledByTierEnding}
          </div>
        );
      case PageDisabledTypes.PageAccessDenied:
        return AccessDeniedConstants.AccessDeniedDescription;
      default:
        return <></>;
    }
  };

  const getIcon = (height: number, width: number) => {
    switch (pageDisabledType) {
      case PageDisabledTypes.NoPermission:
        return <NoPermissionIcon height={height} width={width} />;
      case PageDisabledTypes.ProductDisabled:
        return <ProductDisabledIcon height={height} width={width} />;
      case PageDisabledTypes.SubscriptionTier:
        return <DisabledByTierIcon height={height} width={width} />;
      case PageDisabledTypes.PageAccessDenied:
        return <AccessDeniedIcon height={height} width={width} />;
      default:
        return <></>;
    }
  };

  const renderAccessDeniedButton = () => {
    if (pageDisabledType === PageDisabledTypes.NoPermission) {
      return (
        <div className="feature-disabled-btn">
          <Button buttonType={ButtonType.Primary} onClick={handleLogout}>
            {AccessDeniedConstants.AccessDeniedButtonText}
          </Button>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className="feature-disabled-body">
      {getIcon(200, 200)}
      <div>
        <h5>{getTitle()}</h5>
        <p className="feature-disabled-body-text">{getDescription()}</p>
        <br />
        {renderAccessDeniedButton()}
      </div>
    </div>
  );
};

export default FeatureDisabled;
