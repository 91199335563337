import { FC } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as signalR from "@microsoft/signalr";

import { LogoutCause } from "common/enums";
import { AppState } from "store";
import { API } from "services/api/api-config";
import { resetCookie } from "helper/HelperFunctions";
import { useAppDispatch } from "common/hooks/redux-hooks";
import { setUserPrivilege } from "store/slices/auth-slice";
import Axios from "services/api/axios-interceptor";

const bearerPrefix = "Bearer ";

interface SignalRWebSocketProps {
  companyId: number;
}

export interface UserAutoLogoutNotificationMessage {
  users: number[];
  logoutCause: LogoutCause;
  companyId: number;
}

export const SignalRWebSocket: FC<SignalRWebSocketProps> = ({ companyId }) => {
  const [signalRBaseUrl, setSignalRBaseUrl] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [userPayload, setUserPayload] = useState<string>("");
  const [group, setGroup] = useState<string>("");

  const { user } = useSelector((appState: AppState) => appState.authReducer.authentication);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setToken(user.access_token);
    getSocketUrl();
  }, []);

  useEffect(() => {
    if (signalRBaseUrl !== "" && user.profile.user_id > 0) {
      setUserPayload(companyId + "_" + user.profile.user_id);
      setGroup(getWebSocketGroup());
      signalRConnectionInit();
    }
  }, [signalRBaseUrl, userPayload, group]);

  const getSocketUrl = () => {
    if (signalRBaseUrl == "") {
      getWebSocketUrl();
    }
  };

  const getWebSocketGroup = () => {
    const group = "00000000" + companyId;
    return "suite" + group.substr(group.length - 8);
  };

  const signalRConnectionInit = () => {
    Axios.post(`https://cp-af-eus-stg-suite-signalr.azurewebsites.net/signalr/negotiate`, null, {
      "x-ms-signalr-userid": userPayload
    })
      .then((response: any) => {
        response.accessToken = response.accessToken || response.accessKey;
        const url = response.data.url || response.data.endpoint;

        const options = {
          accessTokenFactory: () => response.data.accessToken
        };

        const connection = new signalR.HubConnectionBuilder()
          .withUrl(url, options)
          .withAutomaticReconnect()
          .configureLogging(signalR.LogLevel.Information)
          .build();
        console.log(connection);
        connection.on("UserPrivilegesChanged", notifyUserPrivilegeChange);

        connection.onclose(() => {
          console.log("disconnected");
          startConnection(connection);
        });
        startConnection(connection);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const startConnection = (connection: any) => {
    console.log("connecting...");
    connection
      .start()
      .then(() => {
        console.log("connected!");
        if (userPayload && userPayload.length > 0) {
          addGroup();
          connection.invoke("getConnectionId");
        }
      })
      .catch((err: any) => {
        console.error(err);
        setTimeout(startConnection, 5000, connection);
      });
  };

  const addGroup = () => {
    Axios.post(
      `${signalRBaseUrl}/signalr/AddToGroup`,
      {
        recipient: userPayload,
        groupname: group
      },
      {
        "x-ms-signalr-userid": userPayload
      }
    )
      .then((resp: any) => {
        if (resp.status == 200) {
          console.log("User added to the group successfully");
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const removeGroup = () => {
    Axios.post(
      `${signalRBaseUrl}/signalr/RemoveFromGroup`,
      {
        recipient: userPayload,
        groupname: group
      },
      {
        credentials: "include",
        method: "GET"
      }
    )
      .then((resp: any) => {
        if (resp.status == 200) {
          console.log("User removed from group successfully");
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const getWebSocketUrl = () => {
    Axios.get(`${API.WEB_SOCKET}/connection-url`, { credentials: "include", method: "GET" })
      .then((response: any) => {
        setSignalRBaseUrl(response);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const notifyUserPrivilegeChange = (notificationMessage: UserAutoLogoutNotificationMessage) => {
    if (notificationMessage.users.includes(user.profile.user_id) && notificationMessage.companyId === companyId) {
      resetCookie("userAutoLogout", "1");
      dispatch(setUserPrivilege({ userPrivilegesChanged: true, logoutCause: notificationMessage.logoutCause }));
    }
  };

  return <></>;
};

export default SignalRWebSocket;
