import { ThunkAction, Action } from "@reduxjs/toolkit";
import { ProductType } from "@sssuite-component-ui/ss-suite-layout";

import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { setIsLoading, setLeftMenuData, setSuiteUrl, setWidgetMenuData } from "store/slices/layout-slice";
import { getLeftMenuRouteData } from "utilities/rbac/rbac-helper";

export const getProductLeftMenuItems = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    Axios.get(`${API.MENU.PRODUCT_LEFT_MENU}/${ProductType.TicTieCalculate}`, {
      method: "GET",
      credentials: "include"
    })
      .then((response) => response)
      .then((data) => {
        const response: LeftMenuModel = {
          leftMenuData: data,
          routeList: getLeftMenuRouteData(data)
        };
        dispatch(setLeftMenuData(response));
      });
  } catch (error: any) {
    dispatch(setIsLoading(false));
  }
};

export const getProductWidgetMenuItems = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  try {
    Axios.get(`${API.MENU.PRODUCT_WIDGET_MENU}/${ProductType.TicTieCalculate}`, {
      method: "GET",
      credentials: "include"
    })
      .then((response) => response)
      .then((data) => {
        const response: WidgetMenuModel = {
          isLoading: false,
          widgetMenu: data,
          suiteUrl: ""
        };
        dispatch(setWidgetMenuData(response));
      });
  } catch (error: any) {
    // handle error
  }
};

export const getSuiteUrl = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  try {
    Axios.get(`${API.SUITE_HELPER.GET_SUITE_URL}`, {
      method: "GET",
      credentials: "include"
    }).then((response) => {
      dispatch(setSuiteUrl(response));
    });
  } catch (error: any) {
    // handle error
  }
};
