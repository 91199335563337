import { Action, ThunkAction } from "@reduxjs/toolkit";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { setIsLoading, setIsOTPValid, setMfaOTPLength } from "store/slices/otp-slice";

export const getMfaOTPLength = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    Axios.get(`${API.SUITE_HELPER.GET_MFA_OTP_LENGTH}`, {
      method: "GET",
      credentials: "include"
    }).then((response: any) => {
      dispatch(setMfaOTPLength(response));
    });
  } catch (error: any) {
    // handle error
    dispatch(setIsLoading(false));
  }
};

export const validateOTP =
  (otp: string, mobileNumber: string, callback: (isOtpValid: boolean) => void): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      Axios.post(
        `${API.SUITE_OTP.VERIFY_OTP}`,
        { mobileNumber, otp },
        {
          method: "GET",
          credentials: "include"
        }
      ).then((response: any) => {
        dispatch(setIsOTPValid(response));
        callback && callback(response);
      });
    } catch (error: any) {
      // handle error
      dispatch(setIsLoading(false));
    }
  };

export const sendOTP =
  (mobileNumber: string, countryCode: string): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      Axios.post(
        `${API.SUITE_OTP.SEND_OTP}`,
        { mobileNumber, countryCode },
        {
          method: "GET",
          credentials: "include"
        }
      ).then((response: any) => {
        dispatch(setIsLoading(false));
      });
    } catch (error: any) {
      // handle error
      dispatch(setIsLoading(false));
    }
  };
