import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  addChildNode,
  collapseAllNodes,
  deleteChildNode,
  expandAllNodes,
  indentNodeLeft,
  moveNodeDown,
  moveNodeUp,
  toggleNodeExpand,
  updateNode
} from "./../../common/components/bookmark-editor/helper/helperFunctions";

export interface BookmarkTreeState {
  isLoading: boolean;
  selectedBookmark: BookmarkTreeModel | null;
  data: BookmarkTreeModel[];
  isValid: boolean;
}

export const initialBookmarkTreeState: BookmarkTreeState = {
  isLoading: false,
  selectedBookmark: null,
  data: [
    {
      id: 0,
      name: "New Template",
      expanded: true,
      items: [
        { id: 1, name: "Bookmark 1", expanded: true, canEdit: false, items: [] },
        { id: 2, name: "Bookmark 2", expanded: true, canEdit: false, items: [] },
        { id: 3, name: "Bookmark 3", expanded: true, canEdit: false, items: [] }
      ]
    }
  ],
  isValid: false
};

const bookmarkTreeSlice = createSlice({
  name: "bookmarkTree",
  initialState: initialBookmarkTreeState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<BookmarkTreeState>) => {
      state.isLoading = action.payload.isLoading;
    },
    setBookmarkData: (state, action: PayloadAction<BookmarkTreeModel[]>) => {
      state.data = action.payload;
    },
    selectBookmark: (state, action: PayloadAction<BookmarkTreeModel | null>) => {
      state.selectedBookmark = action.payload;
    },
    addBookmark: (state, action: PayloadAction<any>) => {
      state.data = addChildNode(state.data, action.payload.parentId, action.payload.canEdit);
    },
    editBookmark: (state, action: PayloadAction<any>) => {
      state.data = updateNode(state.data, action.payload.id, action.payload.name, action.payload.canEdit);
    },
    deleteBookmark: (state, action: PayloadAction<any>) => {
      state.data = deleteChildNode(state.data, action.payload);
    },
    moveBookmarkUp: (state, action: PayloadAction<any>) => {
      state.data = moveNodeUp(state.data, action.payload);
    },
    moveBookmarkDown: (state, action: PayloadAction<any>) => {
      state.data = moveNodeDown(state.data, action.payload);
    },
    expandAllBookmarks: (state) => {
      state.data = expandAllNodes(state.data);
    },
    collapseAllBookmarks: (state) => {
      state.data = collapseAllNodes(state.data);
    },
    indentBookmarkLeft: (state, action: PayloadAction<any>) => {
      state.data = indentNodeLeft(state.data, action.payload);
    },
    indentBookmarkRight: (state, action: PayloadAction<any>) => {
      state.data = indentNodeLeft(state.data, action.payload);
    },
    toggleBookmarkExpand: (state, action: PayloadAction<any>) => {
      state.data = toggleNodeExpand(state.data, action.payload);
    },
    setIsValid: (state, action: PayloadAction<boolean>) => {
      state.isValid = action.payload;
    }
  }
});

export const {
  setIsLoading,
  setBookmarkData,
  selectBookmark,
  addBookmark,
  editBookmark,
  deleteBookmark,
  moveBookmarkUp,
  moveBookmarkDown,
  expandAllBookmarks,
  collapseAllBookmarks,
  indentBookmarkLeft,
  indentBookmarkRight,
  toggleBookmarkExpand,
  setIsValid
} = bookmarkTreeSlice.actions;

export default bookmarkTreeSlice.reducer;
