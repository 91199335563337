import { BrowserRouter } from "react-router-dom";
import AppRoutes from "route/Routes";
import ErrorBoundary from "common/components/pages/ErrorPageBoundary";
import "./App.scss";
import "styles/index.scss";

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  );
}

export default App;
