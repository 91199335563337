import { createSlice } from "@reduxjs/toolkit";

const initialLoginHistoryState: UserLoginHistoryState = {
  loginHistory: [],
  isLoading: false,
  count: 0,
  deviceId: "",
  lastLoginPrompt: false
};

const loginHistorySlice = createSlice({
  name: "loginHistory",
  initialState: initialLoginHistoryState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLoginHistory: (state, action) => {
      state.loginHistory = action.payload.loginHistory;
      state.isLoading = false;
      state.count = action.payload.count || 0;
    },
    setLastLoginPrompt: (state, action) => {
      state.lastLoginPrompt = action.payload;
    }
  }
});
export const { setIsLoading, setLoginHistory, setLastLoginPrompt } = loginHistorySlice.actions;
export default loginHistorySlice.reducer;
