import { PasswordSettings } from "./../common/types/companySettings";
import { ProfileInformation, ValidationContants } from "./constants";
import { UserProfile } from "common/types/userProfile";
import { AppNotifier } from "common/components/toaster/AppNotifier";
import { PasswordOptions } from "common/enums";

export interface IValidationResult {
  isError: boolean;
  errorDescription: string;
}

export function isValidEmailAddress(emailAddress: any) {
  if (emailAddress) {
    const pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    return pattern.test(emailAddress);
  }
  return false;
}

export function validateFax(faxId: any, hideMessage?: boolean) {
  const fax = faxId.trim();
  if (fax.trim().length < 10 || /^[0-9]{10}$/.test(fax) === false) {
    !hideMessage && AppNotifier.Warning(ValidationContants.FaxWarning);
    return false;
  } else return true;
}

export function validatePhone(phoneNoId: any) {
  const phoneNo = phoneNoId.trim();
  if (phoneNo.trim() == "") {
    AppNotifier.Warning(ValidationContants.PhoneNumberWarning);
    return false;
  } else if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
    AppNotifier.Warning(ValidationContants.PhoneNumberLengthWarning);
    return false;
  } else return true;
}

export function validatePassword(passwordId: any) {
  const password = passwordId.trim();
  if (password.trim().length < 8) {
    AppNotifier.Warning(ValidationContants.PasswordLengthWarning);
    return false;
  } else if (password.trim().indexOf(" ") >= 0) {
    AppNotifier.Warning(ValidationContants.PasswordWithoutSpaceWarning);
    return false;
  }
}

export function validateValidCity(CityId: any) {
  var City = CityId?.trim();
  if (/^(?:[a-zA-Z]+(?:[.'\-,])?\s?)+$/.test(City) == false) {
    return false;
  } else return true;
}

export function ValidateWebsite(website: string) {
  if (
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
      website
    ) === false
  ) {
    return false;
  } else {
    return true;
  }
}

export function validateZipLength(ZipNoId: any) {
  var ZipNo = ZipNoId?.trim();
  if (ZipNo.trim().length > 5 || ZipNo.trim().length < 5 || /^[0-9]{5}$/.test(ZipNo) == false) {
    return false;
  } else return true;
}

function hasLowerCase(data: string) {
  const pattern = new RegExp(".*[a-z].*");
  return pattern.test(data);
}

function hasUpperCase(data: string) {
  const pattern = new RegExp(".*[A-Z].*");
  return pattern.test(data);
}

function hasNumber(data: string) {
  const pattern = new RegExp(/[\d]/i);
  return pattern.test(data);
}

function hasSpecialCharacter(data: string) {
  const pattern = new RegExp(/[~!@#$%^&*_]/i);
  return pattern.test(data);
}

export function isValidDate(date: any) {
  const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  return date_regex.test(date);
}

export function isDigit(zip: any) {
  const date_regex = /^[0-9]{1,10}$/;
  return date_regex.test(zip);
}

export function NullandEmptyCheck(text: string) {
  if (text == undefined || text == null || text.trim() == "") {
    return false;
  }
  return true;
}

export function isValidatePTIN(ptin: string) {
  const ptin_regex = /^[Pp][0-9]{8}$/;
  return ptin_regex.test(ptin) || ptin === "";
}

export function validatePhoneLength(phoneNoId: any) {
  const phoneNo = phoneNoId.trim();
  if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
    return false;
  } else return true;
}

export function validateFaxLength(FaxId: any) {
  const Fax = FaxId.trim();
  if (Fax.trim().length < 10 || /^[0-9]{10}$/.test(Fax) == false) {
    return false;
  } else return true;
}

export function validatePasswordLength(passwordId: any) {
  const password = passwordId.trim();
  if (password.length < 8) {
    return false;
  }
  return true;
}
export function validatePasswordSpace(passwordId: any) {
  const password = passwordId.trim();
  if (password.indexOf(" ") >= 0) {
    return false;
  }
  return true;
}

export function ValidateTenDigitNumber(event: any) {
  const pattern = new RegExp(/^[0-9]{0,10}$/);
  return pattern.test(event.target.value);
}

export function ValidateTenDigitphoneNumber(value: string) {
  const pattern = new RegExp(/^[0-9\-\s)\(]{0,14}$/);
  return pattern.test(value);
}
export function ValidateName(value: string) {
  const name = value;
  if (name.length > ProfileInformation.NameLength) {
    return false;
  }
  return true;
}

export function ValidateTrustedDeviceExpiryDays(value: number) {
  if (value > 0 && value < 61) {
    return true;
  }
}

export function ValidateIPAdress(ipAddress: string): boolean {
  var ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
  return ipRegex.test(ipAddress);
}

export function convertIPAddresstoNumber(ipAddress: any): number {
  return Number(
    ipAddress
      .split(".")
      .map((d: any) => ("000" + d).substr(-3))
      .join("")
  );
}

export function validatePasswordBasedOnPolicy(password: any, passwordSettings: PasswordSettings): IValidationResult {
  password = password.trim();
  const passwordOptions = passwordSettings.passwordOptions;
  if (password.trim().length < passwordSettings.length) {
    return {
      isError: true,
      errorDescription: "Password must be minimum " + passwordSettings.length + " characters."
    };
  } else if (password.trim().indexOf(" ") >= 0) {
    return {
      isError: true,
      errorDescription: ValidationContants.PasswordWithoutSpaceWarning
    };
  } else if ((passwordOptions & PasswordOptions.UpperCase) === PasswordOptions.UpperCase && !hasUpperCase(password)) {
    return {
      isError: true,
      errorDescription: ValidationContants.UpperCaseValidationError
    };
  } else if ((passwordOptions & PasswordOptions.LowerCase) === PasswordOptions.LowerCase && !hasLowerCase(password)) {
    return {
      isError: true,
      errorDescription: ValidationContants.LowerCaseValidationError
    };
  } else if ((passwordOptions & PasswordOptions.Numbers) === PasswordOptions.Numbers && !hasNumber(password)) {
    return {
      isError: true,
      errorDescription: ValidationContants.NumbersValidationError
    };
  } else if (
    (passwordOptions & PasswordOptions.SpecialCharacters) === PasswordOptions.SpecialCharacters &&
    !hasSpecialCharacter(password)
  ) {
    return {
      isError: true,
      errorDescription: ValidationContants.SpecialCharactersValidationError
    };
  }

  return {
    isError: false,
    errorDescription: "Success"
  };
}

export const simplePhoneValidation = (char: string, callback: (char: string) => void) => {
  const regNumber = /^[0-9]+$/;
  if (char.length > 10) return false;
  if (!char || char.match(regNumber)) callback(char);
};

export const isControlDisabled = (property: string, profile: UserProfile): boolean => {
  return profile.readonlyFields.some((field) => field === property);
};

export function isValidGuid(guid: string) {
  const guid_regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guid_regex.test(guid);
}
