import { FC } from "react";
import "./../styles/ErrorPage.scss";

interface ErrorPageProps {
  isResetEnabled?: boolean;
  resetError?: () => void;
}
const ErrorPage: FC<ErrorPageProps> = ({ isResetEnabled, resetError }) => {
  const resetErrorBoundary = () => {
    resetError && resetError();
    window.location.reload();
  };
  return (
    <div className={"error-page"}>
      <div className="error-message">Oops! Something went wrong ..</div>
      {isResetEnabled && (
        <div>
          <button className={"retry-button"} onClick={resetErrorBoundary}>
            🔄 Try Again!
          </button>
        </div>
      )}
    </div>
  );
};
export default ErrorPage;
