import React, { useEffect, useState } from "react";
import { Button as TtcButton, ButtonProps } from "react-bootstrap";
import "./Button.scss";

export enum IconPosition {
  Left = "left",
  Right = "right",
  Top = "top",
  Bottom = "bottom",
  Center = "center"
}

export enum ButtonType {
  Primary,
  Secondary,
  OutlinePrimary,
  OutlineSecondary,
  Link,
  Success,
  Danger,
  Light,
  OutlineLight,
  IconOnly
}

interface TtcButtonProps extends ButtonProps {
  children: any;
  /** This property specifies how the button should like ,
   *  possible types are "Primary",
   * "Secondary",
   * "OutlinePrimary",
   * "OutlineSecondary",
   * "Link",
   * "Success",
   * "Danger",
   * "Light",
   * "OutlineLight",
   * "IconOnly" */
  buttonType?: ButtonType;
  iconPosition?: IconPosition | undefined;
  className?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
}
/** Button component which expects buttonType property for button with only text and iconPosition for button with icon */
const Button: React.FC<TtcButtonProps> = ({ children, buttonType, iconPosition, className, disabled, onClick, ...props }) => {
  const [buttonStyle, setButtonStyle] = useState(className || "");

  useEffect(() => {
    setButtonStyle(getButtonStyle());
  }, []);

  const getButtonStyle = () => {
    let style: string = className ? `ss-btn ${className}` : "ss-btn";
    switch (buttonType) {
      case ButtonType.Primary:
        return `${style} ss-btn-primary icon-${iconPosition || "left"}`;

      case ButtonType.OutlinePrimary:
        return `${style} ss-btn-outline-primary icon-${iconPosition || "left"}`;

      case ButtonType.Secondary:
        return `${style} ss-btn-secondary icon-${iconPosition || "left"}`;

      case ButtonType.OutlineSecondary:
        return `${style} ss-btn-outline-secondary icon-${iconPosition || "left"}`;

      case ButtonType.Link:
        return `${style} ss-btn-link icon-${iconPosition || "left"}`;

      case ButtonType.Danger:
        return `${style} ss-btn-danger icon-${iconPosition || "left"}`;

      case ButtonType.Success:
        return `${style} ss-btn-success icon-${iconPosition || "left"}`;

      case ButtonType.Light:
        return `${style} ss-btn-light icon-${iconPosition || "left"}`;

      case ButtonType.OutlineLight:
        return `${style} ss-btn-outline-light icon-${iconPosition || "left"}`;

      case ButtonType.IconOnly:
        return `${style} icon-only icon-center`;

      default:
        return style;
    }
  };

  return (
    <div className="button-container">
      <TtcButton className={buttonStyle} onClick={onClick} disabled={disabled} {...props}>
        {children}
      </TtcButton>
    </div>
  );
};

export default Button;
