export enum ProductTypes {
  General = "General",
  SafeSend_Returns = "SafeSend Returns",
  SafeSend_Signatures = "SafeSend Signatures",
  SafeSend_Organizers = "SafeSend Organizers",
  SafeSend_Gather = "SafeSend Gather",
  SafeSend_TicTieCalculate = "SafeSend TicTie Calculate"
}

export enum LogoutCause {
  None = 0,
  UserPermissionChange = 1,
  UserDelete = 2,
  ProductTierChange = 3,
  CompanyDisable = 4
}

export enum ProcessSteps {
  ClientInfo = 0,
  Organizer = 1,
  ESignDocuments = 2,
  CustomQuestions = 3,
  RequestDocuments = 4,
  DeliveryOptions = 5
}

export enum AuthenticationProvider {
  None = 0,
  AzureAD = 1,
  SAML = 2,
  Google = 3
}

export enum NotifierTypes {
  Error,
  Success,
  Warning,
  Info,
  None
}

export enum PageDisabledTypes {
  SubscriptionTier = 1,
  ProductDisabled = 2,
  NoPermission = 3,
  PageAccessDenied = 4
}

export enum PasswordOptions {
  None = 0,
  UpperCase = 1,
  LowerCase = 2,
  Numbers = 4,
  SpecialCharacters = 8
}

export enum PasswordPolicyType {
  NoPolicy = "NoPolicy",
  SSRDefault = "SSRDefault",
  FirmDefinedPolicy = "FirmDefinedPolicy"
}
