import { FC, useEffect } from "react";
import { createCookie } from "@sssuite-component-ui/session-timeout";

import Modal from "./modal/Modal";
import { UserAutoLogoutModalConstants } from "helper/constants";
import Button, { ButtonType } from "./button/Button";
import { useAppDispatch } from "common/hooks/redux-hooks";
import { setUserPrivilege } from "store/slices/auth-slice";
import { LogoutCause } from "common/enums";
import { signoutRedirect } from "oidc-client/userService";
import { checkIfUserPermissionsChanged } from "helper/HelperFunctions";

interface UserResourceChangeModalProps {
  show: boolean;
  logoutCause: LogoutCause;
  onHide: () => void;
  onResetCache: (callback?: () => void) => void;
}

const UserResourceChangeModal: FC<UserResourceChangeModalProps> = ({ show, onHide, logoutCause, onResetCache }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (show && logoutCause !== LogoutCause.None) {
      onResetCache();
      const expires = new Date();
      expires.setTime(expires.getTime() + 4 * 60 * 1000);
      createCookie("userAutoLogout", "1", expires);
    } else {
      checkIfUserPermissionsChanged() && onHide();
    }
  }, [show, logoutCause]);

  const handleOnSubmit = () => {
    dispatch(setUserPrivilege({ userPrivilegesChanged: false, logoutCause: LogoutCause.None }));
    document.cookie = "userPermissionChanged=false;";
    onResetCache(() => signoutRedirect());
  };

  const modalFooter = () => {
    return (
      <>
        <Button buttonType={ButtonType.Primary} onClick={handleOnSubmit}>
          {UserAutoLogoutModalConstants.SubmitButtonText}
        </Button>
      </>
    );
  };

  return (
    <Modal title={UserAutoLogoutModalConstants.HeaderTitle} isOpen={show} onClose={onHide} footer={modalFooter()}>
      {UserAutoLogoutModalConstants.Message}
    </Modal>
  );
};

export default UserResourceChangeModal;
