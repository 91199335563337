import { Action, ThunkAction } from "@reduxjs/toolkit";
import { NotifierTypes } from "common/enums";
import { PasswordModel, PasswordPolicySettings, PasswordState } from "common/types/password";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { setNotification } from "store/slices/notification-slice";
import { setIsLoading, setPassword, setPasswordPolicy } from "store/slices/password-slice";

export const getPasswordPolicySettings = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    Axios.get(`${API.SUITE_SETTINGS.PASSWORD_POLICY}`, {
      method: "GET",
      credentials: "include"
    }).then((response: any) => {
      dispatch(setPasswordPolicy(response));
    });
  } catch (error: any) {
    // handle error
    dispatch(setIsLoading(false));
    dispatch(setNotification({ message: error.message, type: NotifierTypes.Error }));
  }
};

export const savePassword =
  (
    passwordState: PasswordModel,
    notification: boolean,
    onResponse: (response: any, error: any) => void
  ): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    dispatch(setIsLoading(true));
    Axios.put(`${API.SUITE_USER_MANAGEMENT.USER_PASSWORD}`, passwordState, {
      method: "GET",
      credentials: "include"
    })
      .then((response: any) => {
        dispatch(setPassword(passwordState));
        onResponse && onResponse(response, null);
      })
      .catch((error: any) => {
        // handle error
        dispatch(setIsLoading(false));        
        onResponse && onResponse(null, error);
      });
  };
