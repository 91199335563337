import { createSlice } from "@reduxjs/toolkit";
import { CompanySettingsState } from "common/types/companySettings";

const initialState: CompanySettingsState = {
  companySettings: {
    signOffEnforce: {
      signOffsEnforceLevelOrder: 0
    }
  },
  isLoading: false
};

const companySettingsSlice = createSlice({
  name: "companySettings",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSignOffEnforce(state, action) {
      const parsedValue = JSON.parse(action.payload.value);
      state.companySettings.signOffEnforce.signOffsEnforceLevelOrder = parsedValue.SignOffsEnforceLevelOrder;
      state.isLoading = false;
    }
  }
});

export const { setIsLoading, setSignOffEnforce } = companySettingsSlice.actions;
export default companySettingsSlice.reducer;
