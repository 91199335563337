/* This is to keep all the API enpoints in one place . Here, You can add/update any API route and You can add/update any API endpoint
   You can use this endpoints anywhere in the application by importing the API object
   Sample usage:
   For this enpoint `https://orion-dev-organizers-cpa-api.safesendwebsites.com/api/organizer/clients/GetClientInfoAsync`,
   You can use the below 
   {API.ORGANIZER.CLIENTS.GET_CLIENT_INFO_ASYNC}
*/

const API_BASE_URL = `${process.env.REACT_APP_API_URL}/api`;
const SSSUITE_API_BASE_URL = `${process.env.REACT_APP_SSSUITE_API_URL}/api`;

// API routes
const MY_ACCOUNT = "MyAccount";
const USER_SETTINGS = "UserSettings";
const SETTINGS = "settings";
const SUITE_USER_MANAGEMENT = "user-management";
const SUITE_HELPER = "helper";
const SUITE_SETTINGS = "settings";
const SUITE_USER_GROUP = "user-group";
const SUITE_LOGO = "logo";
const USER = "user";
const SUITE_MENU = "menu";
const SUITE_OTP = "otp";
export const BOOKMARK_TEMPLATE = `${API_BASE_URL}/bookmark-templates`;
export const CUSTOM_SYMBOLS = `${API_BASE_URL}/customSymbols`;
  
// API endpoints
export const API = {
  // login and user
  STAY_ALIVE_ASYNC: `${API_BASE_URL}/${MY_ACCOUNT}/StayAliveAsync`,
  WEB_SOCKET: `${API_BASE_URL}/web-socket`,
  USER: {
    SESSION_IDLE_MINUTES: `${API_BASE_URL}/${USER}/session-timeout-minutes`
  },
  USER_SETTINGS: {
    DEFAULT: `${API_BASE_URL}/${USER_SETTINGS}/Default`
  },
  SUITE_USER_MANAGEMENT: {
    USER_DETAILS: `${SSSUITE_API_BASE_URL}/${SUITE_USER_MANAGEMENT}/user-details`,
    RESET_USER_STATUS_CACHE: `${SSSUITE_API_BASE_URL}/${SUITE_USER_MANAGEMENT}/reset-user-status-cache`,
    LAST_LOGIN_USER_SETTINGS: `${SSSUITE_API_BASE_URL}/${SUITE_USER_MANAGEMENT}/last-login-user-settings`,
    USER_PASSWORD: `${SSSUITE_API_BASE_URL}/${SUITE_USER_MANAGEMENT}/user-password`
  },
  SUITE_LOGIN_HISTORY: {
    GET_LOGIN_HISTORY: `${SSSUITE_API_BASE_URL}/login-history`,
    UPDATE_LOGOUT_DATE: `${SSSUITE_API_BASE_URL}/login-history/update-logout-date`
  },
  SUITE_HELPER: {
    GET_MFA_OTP_LENGTH: `${SSSUITE_API_BASE_URL}/${SUITE_HELPER}/mfa-otp-length`,
    GET_SUITE_URL: `${SSSUITE_API_BASE_URL}/${SUITE_HELPER}/suite-url`
  },
  SUITE_OTP: {
    SEND_OTP: `${SSSUITE_API_BASE_URL}/${SUITE_OTP}/send-otp`,
    VERIFY_OTP: `${SSSUITE_API_BASE_URL}/${SUITE_OTP}/verify-otp`
  },
  SUITE_SETTINGS: {
    PASSWORD_POLICY: `${SSSUITE_API_BASE_URL}/${SUITE_SETTINGS}/password-policy`
  },
  SUITE_USER_GROUP: {
    GET_USER_ROLES: `${SSSUITE_API_BASE_URL}/${SUITE_USER_GROUP}/user-roles`
  },
  SUITE_LOGO: {
    COMPANY_LOGO: `${SSSUITE_API_BASE_URL}/${SUITE_LOGO}/company-logo`,
    COMPANY_WHITE_LOGO: `${SSSUITE_API_BASE_URL}/${SUITE_LOGO}/company-whitelogo`
  },
  MENU: {
    PRODUCT_LEFT_MENU: `${SSSUITE_API_BASE_URL}/${SUITE_MENU}/product-left-menu`,
    PRODUCT_WIDGET_MENU: `${SSSUITE_API_BASE_URL}/${SUITE_MENU}/widget-menu`
  },
  BOOKMARK_TEMPLATE: {
    DUPLICATE: `${BOOKMARK_TEMPLATE}/duplicate`
  },
  COMPANY: {
    PRODUCT: {
      IS_ENABLED: `${API_BASE_URL}/company/product/is-enabled`,
      IS_SUBSCRIBED: `${API_BASE_URL}/company/product/is-subscribed`
    },
    SETTINGS: `${API_BASE_URL}/company/${SETTINGS}`
  }
};
