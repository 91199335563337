import { PayloadAction, createSlice } from "@reduxjs/toolkit";
//import { setIsValid } from "./bookmark-tree-slice";

interface CustomSymbolsUploadState {
  isLoading: boolean;
  sasUri: string | null;
  fileName: string;
  documentUniqueId: string | null;
  base64Image: string | null;
  customSymbols: CustomSymbolModel[]; 
  isValid?: boolean;

}

interface CustomSymbolModel {
  companyID: string;
  imageName: string;
  image: string;
  active: string;
}

const initialCustomSymbolsUploadState: CustomSymbolsUploadState = {
  isLoading: false,
  sasUri: null,
  fileName: "",
  documentUniqueId: null,
  base64Image: null,
  customSymbols: [], // Initialize with an empty array
  isValid: false,
};

const customSymbolsUploadSlice = createSlice({
  name: "customSymbolsUpload",
  initialState: initialCustomSymbolsUploadState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setFileName: (state, action: PayloadAction<string>) => {
      state.fileName = action.payload;
    },
    setDocumentUniqueId: (state, action: PayloadAction<string>) => {
      state.documentUniqueId = action.payload;
    },
    setCustomSymbols: (state, action: PayloadAction<CustomSymbolModel[]>) => {
      state.customSymbols = action.payload; 
    },
    setIsValid: (state, action: PayloadAction<boolean>) => {
      state.isValid = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setFileName,
  setDocumentUniqueId,
  setCustomSymbols,
  setIsValid,
} = customSymbolsUploadSlice.actions;

export default customSymbolsUploadSlice.reducer;
