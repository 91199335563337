export const DEFAULT = "/";
export const BOOKMARK_TEMPLATE = "/bookmarks";
export const CUSTOM_SYMBOLS = "/customsymbols";
export const SETTINGS = "/settings";
export const FEATURE_DISABLED = "/feature-disabled";

export const LOGIN = "/login";
export const SIGNIN_OIDC = "/signin-oidc";
export const SILENT_SIGNIN_OIDC = "/silent-signin-oidc";
export const SIGNOUT_CALLBACK_OIDC = "/signout-callback-oidc";
