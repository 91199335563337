import { createCookie, getDomain } from "@sssuite-component-ui/session-timeout";
import * as Bowser from "bowser";
import { PasswordOptions } from "common/enums";
import { UserProfile } from "common/types/userProfile";

export const getcookie = (cookieName: string) => {
  const cookieString = document.cookie;
  const cookieArray = cookieString.split(";");
  for (let i = 0; i < cookieArray.length; ++i) {
    if (cookieArray[i].trim().match("^" + cookieName + "=")) {
      return cookieArray[i].replace(`${cookieName}=`, "").trim();
    }
  }
  return null;
};

export const resetCookie = (cookieName: string, value: string) => {
  const cookieString = document.cookie;
  const cookieArray = cookieString.split(";");
  for (let i = 0; i < cookieArray.length; ++i) {
    if (cookieArray[i].trim().match("^" + cookieName + "=")) {
      if (cookieArray[i]) return (cookieArray[i] = `${cookieName}=${value}`.trim());
    }
  }
};

export const checkIfUserPermissionsChanged = (): boolean => {
  const name = "UserPermissionChanged=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length) === "true";
    }
  }
  return false;
};

export const getBrowserDetails = (userAgent: string) => {
  const browser = Bowser.getParser(userAgent);
  return (
    browser.getBrowserName() +
    "(" +
    browser.getBrowserVersion() +
    ")" +
    " on " +
    browser.getOSName() +
    "(" +
    browser.getOSVersion() +
    ")"
  );
};

export function phoneNumberDisplay(phoneNumber: string): string {
  return phoneNumber && phoneNumber.length === 10
    ? "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, 10)
    : phoneNumber;
}

export function countryCodeDisplay(countryCode: string): string {
  return countryCode ? "(" + countryCode + ") " : "";
}

export function GetCountryCode() {
  const countryCodeOption = [];
  countryCodeOption.push({ value: "+1", label: "USA/Canada (+1)" });
  countryCodeOption.push({ value: "+213", label: "Algeria (+213)" });
  countryCodeOption.push({ value: "+376", label: "Andorra (+376)" });
  countryCodeOption.push({ value: "+244", label: "Angola (+244)" });
  countryCodeOption.push({ value: "+1264", label: "Anguilla (+1264)" });
  countryCodeOption.push({ value: "+1268", label: "Antigua Barbuda (+1268)" });
  countryCodeOption.push({ value: "+54", label: "Argentina (+54)" });
  countryCodeOption.push({ value: "+374", label: "Armenia (+374)" });
  countryCodeOption.push({ value: "+297", label: "Aruba (+297)" });
  countryCodeOption.push({ value: "+61", label: "Australia (+61)" });
  countryCodeOption.push({ value: "+43", label: "Austria (+43)" });
  countryCodeOption.push({ value: "+994", label: "Azerbaijan (+994)" });
  countryCodeOption.push({ value: "+1242", label: "Bahamas (+1242)" });
  countryCodeOption.push({ value: "+973", label: "Bahrain (+973)" });
  countryCodeOption.push({ value: "+880", label: "Bangladesh (+880)" });
  countryCodeOption.push({ value: "+1246", label: "Barbados (+1246)" });
  countryCodeOption.push({ value: "+375", label: "Belarus (+375)" });
  countryCodeOption.push({ value: "+32", label: "Belgium (+32)" });
  countryCodeOption.push({ value: "+501", label: "Belize (+501)" });
  countryCodeOption.push({ value: "+229", label: "Benin (+229)" });
  countryCodeOption.push({ value: "+1441", label: "Bermuda (+1441)" });
  countryCodeOption.push({ value: "+975", label: "Bhutan (+975)" });
  countryCodeOption.push({ value: "+591", label: "Bolivia (+591)" });
  countryCodeOption.push({ value: "+387", label: "Bosnia Herzegovina (+387)" });
  countryCodeOption.push({ value: "+267", label: "Botswana (+267)" });
  countryCodeOption.push({ value: "+55", label: "Brazil (+55)" });
  countryCodeOption.push({ value: "+673", label: "Brunei (+673)" });
  countryCodeOption.push({ value: "+359", label: "Bulgaria (+359)" });
  countryCodeOption.push({ value: "+226", label: "Burkina Faso (+226)" });
  countryCodeOption.push({ value: "+257", label: "Burundi (+257)" });
  countryCodeOption.push({ value: "+855", label: "Cambodia (+855)" });
  countryCodeOption.push({ value: "+237", label: "Cameroon (+237)" });
  countryCodeOption.push({ value: "+238", label: "Cape Verde Islands (+238)" });
  countryCodeOption.push({ value: "+1345", label: "Cayman Islands (+1345)" });
  countryCodeOption.push({
    value: "+236",
    label: "Central African Republic (+236)"
  });
  countryCodeOption.push({ value: "+56", label: "Chile (+56)" });
  countryCodeOption.push({ value: "+86", label: "China (+86)" });
  countryCodeOption.push({ value: "+57", label: "Colombia (+57)" });
  countryCodeOption.push({ value: "+242", label: "Congo (+242)" });
  countryCodeOption.push({ value: "+682", label: "Cook Islands (+682)" });
  countryCodeOption.push({ value: "+506", label: "Costa Rica (+506)" });
  countryCodeOption.push({ value: "+385", label: "Croatia (+385)" });
  countryCodeOption.push({ value: "+53", label: "Cuba (+53)" });
  countryCodeOption.push({ value: "+90392", label: "Cyprus North (+90392)" });
  countryCodeOption.push({ value: "+357", label: "Cyprus South (+357)" });
  countryCodeOption.push({ value: "+42", label: "Czech Republic (+42)" });
  countryCodeOption.push({ value: "+45", label: "Denmark (+45)" });
  countryCodeOption.push({ value: "+253", label: "Djibouti (+253)" });
  countryCodeOption.push({
    value: "+1809",
    label: "Dominican Republic (+1809)"
  });
  countryCodeOption.push({ value: "+593", label: "Ecuador (+593)" });
  countryCodeOption.push({ value: "+20", label: "Egypt (+20)" });
  countryCodeOption.push({ value: "+503", label: "El Salvador (+503)" });
  countryCodeOption.push({ value: "+240", label: "Equatorial Guinea (+240)" });
  countryCodeOption.push({ value: "+291", label: "Eritrea (+291)" });
  countryCodeOption.push({ value: "+372", label: "Estonia (+372)" });
  countryCodeOption.push({ value: "+251", label: "Ethiopia (+251)" });
  countryCodeOption.push({ value: "+500", label: "Falkland Islands (+500)" });
  countryCodeOption.push({ value: "+298", label: "Faroe Islands (+298)" });
  countryCodeOption.push({ value: "+679", label: "Fiji (+679)" });
  countryCodeOption.push({ value: "+358", label: "Finland (+358)" });
  countryCodeOption.push({ value: "+33", label: "France (+33)" });
  countryCodeOption.push({ value: "+594", label: "French Guiana (+594)" });
  countryCodeOption.push({ value: "+689", label: "French Polynesia (+689)" });
  countryCodeOption.push({ value: "+241", label: "Gabon (+241)" });
  countryCodeOption.push({ value: "+220", label: "Gambia (+220)" });
  countryCodeOption.push({ value: "+7880", label: "Georgia (+7880)" });
  countryCodeOption.push({ value: "+49", label: "Germany (+49)" });
  countryCodeOption.push({ value: "+233", label: "Ghana (+233)" });
  countryCodeOption.push({ value: "+350", label: "Gibraltar (+350)" });
  countryCodeOption.push({ value: "+30", label: "Greece (+30)" });
  countryCodeOption.push({ value: "+299", label: "Greenland (+299)" });
  countryCodeOption.push({ value: "+1473", label: "Grenada (+1473)" });
  countryCodeOption.push({ value: "+590", label: "Guadeloupe (+590)" });
  countryCodeOption.push({ value: "+671", label: "Guam (+671)" });
  countryCodeOption.push({ value: "+502", label: "Guatemala (+502)" });
  countryCodeOption.push({ value: "+224", label: "Guinea (+224)" });
  countryCodeOption.push({ value: "+245", label: "Guinea - Bissau (+245)" });
  countryCodeOption.push({ value: "+592", label: "Guyana (+592)" });
  countryCodeOption.push({ value: "+509", label: "Haiti (+509)" });
  countryCodeOption.push({ value: "+504", label: "Honduras (+504)" });
  countryCodeOption.push({ value: "+852", label: "Hong Kong (+852)" });
  countryCodeOption.push({ value: "+36", label: "Hungary (+36)" });
  countryCodeOption.push({ value: "+354", label: "Iceland (+354)" });
  countryCodeOption.push({ value: "+91", label: "India (+91)" });
  countryCodeOption.push({ value: "+62", label: "Indonesia (+62)" });
  countryCodeOption.push({ value: "+98", label: "Iran (+98)" });
  countryCodeOption.push({ value: "+964", label: "Iraq (+964)" });
  countryCodeOption.push({ value: "+353", label: "Ireland (+353)" });
  countryCodeOption.push({ value: "+972", label: "Israel (+972)" });
  countryCodeOption.push({ value: "+39", label: "Italy (+39)" });
  countryCodeOption.push({ value: "+1876", label: "Jamaica (+1876)" });
  countryCodeOption.push({ value: "+81", label: "Japan (+81)" });
  countryCodeOption.push({ value: "+962", label: "Jordan (+962)" });
  countryCodeOption.push({ value: "+254", label: "Kenya (+254)" });
  countryCodeOption.push({ value: "+686", label: "Kiribati (+686)" });
  countryCodeOption.push({ value: "+850", label: "Korea North (+850)" });
  countryCodeOption.push({ value: "+82", label: "Korea South (+82)" });
  countryCodeOption.push({ value: "+965", label: "Kuwait (+965)" });
  countryCodeOption.push({ value: "+996", label: "Kyrgyzstan (+996)" });
  countryCodeOption.push({ value: "+856", label: "Laos (+856)" });
  countryCodeOption.push({ value: "+371", label: "Latvia (+371)" });
  countryCodeOption.push({ value: "+961", label: "Lebanon (+961)" });
  countryCodeOption.push({ value: "+266", label: "Lesotho (+266)" });
  countryCodeOption.push({ value: "+231", label: "Liberia (+231)" });
  countryCodeOption.push({ value: "+218", label: "Libya (+218)" });
  countryCodeOption.push({ value: "+417", label: "Liechtenstein (+417)" });
  countryCodeOption.push({ value: "+370", label: "Lithuania (+370)" });
  countryCodeOption.push({ value: "+352", label: "Luxembourg (+352)" });
  countryCodeOption.push({ value: "+853", label: "Macao (+853)" });
  countryCodeOption.push({ value: "+389", label: "Macedonia (+389)" });
  countryCodeOption.push({ value: "+261", label: "Madagascar (+261)" });
  countryCodeOption.push({ value: "+265", label: "Malawi (+265)" });
  countryCodeOption.push({ value: "+60", label: "Malaysia (+60)" });
  countryCodeOption.push({ value: "+960", label: "Maldives (+960)" });
  countryCodeOption.push({ value: "+223", label: "Mali (+223)" });
  countryCodeOption.push({ value: "+356", label: "Malta (+356)" });
  countryCodeOption.push({ value: "+692", label: "Marshall Islands (+692)" });
  countryCodeOption.push({ value: "+596", label: "Martinique (+596)" });
  countryCodeOption.push({ value: "+222", label: "Mauritania (+222)" });
  countryCodeOption.push({ value: "+269", label: "Mayotte (+269)" });
  countryCodeOption.push({ value: "+52", label: "Mexico (+52)" });
  countryCodeOption.push({ value: "+691", label: "Micronesia (+691)" });
  countryCodeOption.push({ value: "+373", label: "Moldova (+373)" });
  countryCodeOption.push({ value: "+377", label: "Monaco (+377)" });
  countryCodeOption.push({ value: "+976", label: "Mongolia (+976)" });
  countryCodeOption.push({ value: "+1664", label: "Montserrat (+1664)" });
  countryCodeOption.push({ value: "+212", label: "Morocco (+212)" });
  countryCodeOption.push({ value: "+258", label: "Mozambique (+258)" });
  countryCodeOption.push({ value: "+95", label: "Myanmar (+95)" });
  countryCodeOption.push({ value: "+264", label: "Namibia (+264)" });
  countryCodeOption.push({ value: "+674", label: "Nauru (+674)" });
  countryCodeOption.push({ value: "+977", label: "Nepal (+977)" });
  countryCodeOption.push({ value: "+31", label: "Netherlands (+31)" });
  countryCodeOption.push({ value: "+687", label: "New Caledonia (+687)" });
  countryCodeOption.push({ value: "+64", label: "New Zealand (+64)" });
  countryCodeOption.push({ value: "+505", label: "Nicaragua (+505)" });
  countryCodeOption.push({ value: "+227", label: "Niger (+227)" });
  countryCodeOption.push({ value: "+234", label: "Nigeria (+234)" });
  countryCodeOption.push({ value: "+683", label: "Niue (+683)" });
  countryCodeOption.push({ value: "+672", label: "Norfolk Islands (+672)" });
  countryCodeOption.push({ value: "+670", label: "Northern Marianas (+670)" });
  countryCodeOption.push({ value: "+47", label: "Norway (+47)" });
  countryCodeOption.push({ value: "+968", label: "Oman (+968)" });
  countryCodeOption.push({ value: "+680", label: "Palau (+680)" });
  countryCodeOption.push({ value: "+507", label: "Panama (+507)" });
  countryCodeOption.push({ value: "+675", label: "Papua New Guinea (+675)" });
  countryCodeOption.push({ value: "+595", label: "Paraguay (+595)" });
  countryCodeOption.push({ value: "+51", label: "Peru (+51)" });
  countryCodeOption.push({ value: "+63", label: "Philippines (+63)" });
  countryCodeOption.push({ value: "+48", label: "Poland (+48)" });
  countryCodeOption.push({ value: "+351", label: "Portugal (+351)" });
  countryCodeOption.push({ value: "+1787", label: "Puerto Rico (+1787)" });
  countryCodeOption.push({ value: "+974", label: "Qatar (+974)" });
  countryCodeOption.push({ value: "+262", label: "Reunion (+262)" });
  countryCodeOption.push({ value: "+40", label: "Romania (+40)" });
  countryCodeOption.push({ value: "+7", label: "Russia (+7)" });
  countryCodeOption.push({ value: "+250", label: "Rwanda (+250)" });
  countryCodeOption.push({ value: "+378", label: "San Marino (+378)" });
  countryCodeOption.push({ value: "+239", label: "Sao Tome Principe (+239)" });
  countryCodeOption.push({ value: "+966", label: "Saudi Arabia (+966)" });
  countryCodeOption.push({ value: "+221", label: "Senegal (+221)" });
  countryCodeOption.push({ value: "+381", label: "Serbia (+381)" });
  countryCodeOption.push({ value: "+248", label: "Seychelles (+248)" });
  countryCodeOption.push({ value: "+232", label: "Sierra Leone (+232)" });
  countryCodeOption.push({ value: "+65", label: "Singapore (+65)" });
  countryCodeOption.push({ value: "+421", label: "Slovak Republic (+421)" });
  countryCodeOption.push({ value: "+386", label: "Slovenia (+386)" });
  countryCodeOption.push({ value: "+677", label: "Solomon Islands (+677)" });
  countryCodeOption.push({ value: "+252", label: "Somalia (+252)" });
  countryCodeOption.push({ value: "+27", label: "South Africa (+27)" });
  countryCodeOption.push({ value: "+34", label: "Spain (+34)" });
  countryCodeOption.push({ value: "+94", label: "Sri Lanka (+94)" });
  countryCodeOption.push({ value: "+290", label: "St. Helena (+290)" });
  countryCodeOption.push({ value: "+1869", label: "St. Kitts (+1869)" });
  countryCodeOption.push({ value: "+1758", label: "St. Lucia (+1758)" });
  countryCodeOption.push({ value: "+249", label: "Sudan (+249)" });
  countryCodeOption.push({ value: "+597", label: "Suriname (+597)" });
  countryCodeOption.push({ value: "+268", label: "Swaziland (+268)" });
  countryCodeOption.push({ value: "+46", label: "Sweden (+46)" });
  countryCodeOption.push({ value: "+41", label: "Switzerland (+41)" });
  countryCodeOption.push({ value: "+963", label: "Syria (+963)" });
  countryCodeOption.push({ value: "+886", label: "Taiwan (+886)" });
  countryCodeOption.push({ value: "+66", label: "Thailand (+66)" });
  countryCodeOption.push({ value: "+228", label: "Togo (+228)" });
  countryCodeOption.push({ value: "+676", label: "Tonga (+676)" });
  countryCodeOption.push({ value: "+1868", label: "Trinidad Tobago (+1868)" });
  countryCodeOption.push({ value: "+216", label: "Tunisia (+216)" });
  countryCodeOption.push({ value: "+90", label: "Turkey (+90)" });
  countryCodeOption.push({ value: "+993", label: "Turkmenistan (+993)" });
  countryCodeOption.push({
    value: "+1649",
    label: "Turks Caicos Islands (+1649)"
  });
  countryCodeOption.push({ value: "+688", label: "Tuvalu (+688)" });
  countryCodeOption.push({ value: "+256", label: "Uganda (+256)" });
  countryCodeOption.push({ value: "+44", label: "UK (+44)" });
  countryCodeOption.push({ value: "+380", label: "Ukraine (+380)" });
  countryCodeOption.push({
    value: "+971",
    label: "United Arab Emirates (+971)"
  });
  countryCodeOption.push({ value: "+598", label: "Uruguay (+598)" });
  countryCodeOption.push({ value: "+678", label: "Vanuatu (+678)" });
  countryCodeOption.push({ value: "+379", label: "Vatican City (+379)" });
  countryCodeOption.push({ value: "+58", label: "Venezuela (+58)" });
  countryCodeOption.push({ value: "+84", label: "Virgin Islands" });
  countryCodeOption.push({ value: "+681", label: "Wallis Futuna (+681)" });
  countryCodeOption.push({ value: "+969", label: "Yemen North (+969)" });
  countryCodeOption.push({ value: "+967", label: "Yemen South (+967)" });
  countryCodeOption.push({ value: "+260", label: "Zambia (+260)" });
  countryCodeOption.push({ value: "+263", label: "Zimbabwe (+263)" });
  return countryCodeOption;
}

export const getPasswordOptionLabel = (option: number): string => {
  switch (option) {
    case PasswordOptions.None:
      return "";
    case PasswordOptions.UpperCase:
      return "Upper Case Letter";
    case PasswordOptions.LowerCase:
      return "Lower Case Letter";
    case PasswordOptions.Numbers:
      return "Numbers";
    case PasswordOptions.SpecialCharacters:
      return "Special Character";
    default:
      return "";
  }
};

export const createForethoughtCookie = (key: string, value: string, expiryMiliSeconds?: number) => {
  const domain = getDomain(window.location.href);
  createCookie(key, value, expiryMiliSeconds ?? 24 * 60 * 60 * 1000, `.${domain}`);
};

export function isTTCUser(userRoles: any[]) {
  return userRoles?.includes("TTC - Manager") || false;
}
