import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppState } from "store";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { isTTCUser } from "helper/HelperFunctions";
import { setPageLevelAccess, setTTCEnabled } from "store/slices/post-auth-slice";
import { PageDisabledTypes } from "common/enums";
import LoadingScreen from "common/components/pages/LoadingScreen";
import FeatureDisabled from "common/components/pages/FeatureDisabled";

const RBACRoute: FC<any> = ({ component: Component, ...rest }) => {
  const [productPermission, setProductPermission] = useState<boolean>(false);

  const { ttcEnabled, productEnabled, productSubscribed, isLoading, isPageAccessDenied } = useAppSelector(
    (state: AppState) => state.postAuthReducer
  );
  const { userRoles, isLoading: isLoadingUserProfile } = useAppSelector((state: AppState) => state.userProfileReducer);
  const { leftMenuData } = useAppSelector((state: AppState) => state.layoutReducer);
  const { authentication } = useAppSelector((state: AppState) => state.authReducer);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const hasPermission = isTTCUser(userRoles);
    setProductPermission(hasPermission);
  }, [authentication.user?.profile?.user_id, userRoles]);

  useEffect(() => {
    if (!isLoading || !isLoadingUserProfile || !productEnabled.isLoading || !productSubscribed.isLoading) {
      const ttcEnabled = productSubscribed.isSubscribed && productEnabled.isEnabled && productPermission;
      dispatch(setTTCEnabled(ttcEnabled));
    }
  }, [productEnabled, productSubscribed, productPermission]);

  useEffect(() => {
    // Map over sidemenu to check whether the location pathname is allowed menu
    if (leftMenuData && leftMenuData.length > 0) {
      let isAccessAllowed = true;
      leftMenuData &&
        leftMenuData.forEach((section: any) => {
          section.items.forEach((menu: any) => {
            if (location.pathname.toLocaleLowerCase() === menu.route.toLocaleLowerCase() && menu.disabled) {
              isAccessAllowed = false;
              return;
            }
            menu.items &&
              menu.items?.length > 0 &&
              menu.items.map((subMenu: any) => {
                if (location.pathname.toLocaleLowerCase() === subMenu.route.toLocaleLowerCase() && subMenu.disabled) {
                  isAccessAllowed = false;
                  return;
                }
              });
          });
        });
      dispatch(setPageLevelAccess(isAccessAllowed));
    }
  }, [leftMenuData, location.pathname]);

  const getPageDisableType = () => {
    if (!productSubscribed.isSubscribed) {
      return PageDisabledTypes.SubscriptionTier;
    } else if (!productEnabled.isEnabled) {
      return PageDisabledTypes.ProductDisabled;
    } else if (!productPermission) {
      return PageDisabledTypes.NoPermission;
    } else if (!isPageAccessDenied) {
      return PageDisabledTypes.PageAccessDenied;
    } else {
      return 0;
    }
  };

  return !productEnabled.isLoading &&
    !productSubscribed.isLoading &&
    !isLoading &&
    !isLoadingUserProfile &&
    !authentication.isLoadingUser &&
    authentication.user &&
    authentication.user?.profile?.user_id ? (
    !ttcEnabled || !isPageAccessDenied ? (
      <FeatureDisabled pageDisabledType={getPageDisableType()} />
    ) : (
      <Component {...rest} />
    )
  ) : (
    <LoadingScreen />
  );
};

export default RBACRoute;
