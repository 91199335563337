import { createForethoughtCookie, resetCookie } from "helper/HelperFunctions";
import { createUserManager, createUserManagerWithRedirect } from "./helpers";
import { store } from "store";
import { resetUserCache, updateLogoutDate } from "store/services/auth-service";
// import { resetCookie } from '../components/helper/HelperFunctions'

export var userManager = createUserManager();

export const signinRedirect = () => userManager.signinRedirect();

export const signinRedirectWithCallbackArgs = (args: string) => {
  if (args && !userManager.settings.redirect_uri?.includes("?callback_args=")) {
    const redirectUri = userManager.settings.redirect_uri + "?callback_args=" + encodeURIComponent(args);
    userManager = createUserManagerWithRedirect(redirectUri);
  }
  userManager.signinRedirect();
};

export const signinRedirectCallback = () => userManager.signinRedirectCallback();

export async function signoutRedirect() {
  createForethoughtCookie("isForethoughtWidgetVisible", "false");
  await resetUserCache();
  const user = await userManager.getUser();
  userManager.clearStaleState();
  userManager.removeUser();
  resetCookie("userAutoLogout", "0");
  await updateLogoutDate();
  return userManager.signoutRedirect({ id_token_hint: user?.id_token });
}

export const signoutRedirectCallback = () => {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirectCallback();
};

export async function getAccessToken() {
  const user = store.getState()?.authReducer?.authentication?.user;
  return user?.access_token;
}
