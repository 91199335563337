import { FC } from "react";
import Modal from "../modal/Modal";
import { MyAccountConstants } from "helper/constants";
import MyAccountContainer from "./MyAccountContainer";

interface MyProfileModalProps {
  show: boolean;
  onHide: () => void;
}

const MyProfileModal: FC<MyProfileModalProps> = ({ show, onHide }) => {
  return (
    <Modal className="my-profile-modal" title={MyAccountConstants.ModalTitle} isOpen={show} onClose={onHide} onHide={onHide} size="lg">
      <MyAccountContainer onHide={onHide} />
    </Modal>
  );
};

export default MyProfileModal;
