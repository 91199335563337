import { FC, useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import * as Bowser from "bowser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import { getFormattedLocalDateTime } from "helper/dateHelperFunctions";
import { DateFormatConstants, LoginHistoryConstants } from "helper/constants";
import { getUserLoginHistory } from "store/services/login-history-service";
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const _ = require("lodash");

const LoginHistoryTable: FC = () => {
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const { isLoading, loginHistory, deviceId, count } = useAppSelector((state: AppState) => state.loginHistoryReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserLoginHistory(currentPageNumber, LoginHistoryConstants.CountPerPage));
  }, []);

  const defaultType = (cell: any, row: any) => {
    return (
      <div title={cell} className="ellipsis">
        {cell}
      </div>
    );
  };

  const columns = [
    {
      key: "browser",
      header: "Browser",
      isKey: true,
      dataField: "clientApp",
      isHidden: false,
      width: "40%",
      dataFormat: defaultType
    },
    {
      key: "ipAddress",
      isKey: false,
      header: "IP Address",
      dataField: "ipAddress",
      className: "text-field-header header-text",
      dataSort: true,
      toolTip: false,
      isHidden: false,
      width: "20%",
      dataFormat: defaultType
    },
    {
      key: "loginDate",
      isKey: false,
      header: "Login Date",
      dataField: "loggedInOn",
      width: "20%",
      dataFormat: defaultType
    },
    {
      key: "logoutDate",
      isKey: false,
      header: "Logout Date",
      dataField: "loggedOutOn",
      width: "20%",
      dataFormat: defaultType
    }
  ];

  const getTableData = () => {
    const data = _.cloneDeep(loginHistory);

    data.forEach((history: UserLoginHistory) => {
      let browser = Bowser.getParser(history.clientApp);
      history.clientApp =
        browser.getBrowserName() +
        "(" +
        browser.getBrowserVersion() +
        ")" +
        " on " +
        browser.getOSName() +
        "(" +
        browser.getOSVersion() +
        ")";
      history.loggedInOn = getFormattedLocalDateTime(history.loggedInOn, DateFormatConstants.LastLoginDateFormat);
      if (history.deviceId === deviceId) history.loggedOutOn = "Current Device";
      else if (!history.loggedOutOn) history.loggedOutOn = "Logged In";
      else {
        history.loggedOutOn = getFormattedLocalDateTime(history.loggedOutOn, DateFormatConstants.LastLoginDateFormat);
      }
    });
    return data;
  };

  const renderShowsTotal = (start: number, to: number, total: number) => {
    return (
      <p>
        Showing <b>{start}</b> to <b>{to}</b> of <b>{total}</b> entries
      </p>
    );
  };

  const setNoContent = () => {
    return "No Login History";
  };

  const handlePageChange = (page: number) => {
    dispatch(getUserLoginHistory(page));
    setCurrentPageNumber(page);
  };
  let paginationPosition: "both" | "top" | "bottom" | undefined = "bottom";

  const options = {
    sizePerPage: LoginHistoryConstants.CountPerPage,
    page: currentPageNumber,
    onPageChange: (page: number) => handlePageChange(page),
    paginationShowsTotal: renderShowsTotal,
    noDataText: setNoContent(),
    paginationPosition, // default is bottom, top and both is all available
    nextPage: <FontAwesomeIcon icon={faAngleRight} />,
    prePage: <FontAwesomeIcon icon={faAngleLeft} />,
    firstPage: <FontAwesomeIcon icon={faAngleDoubleLeft} />,
    lastPage: <FontAwesomeIcon icon={faAngleDoubleRight} />,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    hideSizePerPage: true
  };

  return (
    <div className="login-history-table-wrapper">
      {/* Will be implemented later */}
      {/* <button className="reload-button" onClick={handleReload}><RefreshIcon /></button> */}
      <LoadingOverlay>
        <BootstrapTable
          data={getTableData()}
          containerClass="ss-table-container"
          tableHeaderClass="ss-header-border table-header-font"
          trStyle={{ borderBottom: "1px solid #A6A9AC", height: "32px" }}
          bordered={false}
          pagination={true}
          options={options}
          striped
        >
          {columns.map((value, index) => {
            return (
              <TableHeaderColumn
                key={index}
                isKey={value.isKey}
                dataField={value.dataField}
                hidden={value.isHidden}
                width={value.width}
                className={value.className ? value.className : ""}
                columnTitle={value.toolTip}
              >
                <span title={value.header} className="table-text-sort">
                  {value.header}
                </span>
              </TableHeaderColumn>
            );
          })}
        </BootstrapTable>
        <Loader loading={isLoading} />
      </LoadingOverlay>
    </div>
  );
};

export default LoginHistoryTable;
