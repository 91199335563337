import { ProductType } from "@sssuite-component-ui/ss-suite-layout";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { setAuthentication, setResetCacheApiCalled, setSessionIdleMinutes, setUserPrivilege } from "store/slices/auth-slice";

export const userExpired = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  dispatch(setAuthentication(null));
};

export const userFound =
  (user: any): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    dispatch(setAuthentication(user));
  };

export const userRenewed =
  (user: any): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    dispatch(setAuthentication(user));
  };

export const userPrivilegeChanged =
  (payload: any): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    dispatch(setUserPrivilege(payload));
  };

export const resetUserCache =
  (callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    Axios.get(`${API.SUITE_USER_MANAGEMENT.RESET_USER_STATUS_CACHE}/${ProductType.TicTieCalculate}`, {
      credentials: "include",
      method: "GET"
    }).then((response: any) => {
      callback && callback();
      dispatch(setResetCacheApiCalled());
    });
  };

export const getSessionIdleMinutes = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  Axios.get(`${API.USER.SESSION_IDLE_MINUTES}`, {
    credentials: "include",
    method: "GET"
  }).then((response: any) => {
    dispatch(setSessionIdleMinutes(response.data));
  });
};

export const updateLogoutDate = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  Axios.put(`${API.SUITE_LOGIN_HISTORY.UPDATE_LOGOUT_DATE}`, {
    credentials: "include",
    method: "GET"
  }).catch((error: any) => {
    console.log(error);
  });
};
